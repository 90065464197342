<template>
  <v-col
    :cols=cols_number
  >
    <v-img
      :src="require('@/assets/medallas/' + value.image)"
      height="150"
      contain
    />
  </v-col>
</template>

<script>
  export default {
    name: 'medalla',
    props: {
      value: {
        type: Object,
        default: () => ({}),
      },
      cols_number: {
        type: Number,
        default: () => 4,
      },
    },
  }
</script>

<style scoped>

</style>
